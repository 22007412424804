.Teaching-Container {
  display: inline-block;
  height: 300px;
  /* width: 100%; */
}

.Teaching-Header {
  font-family: Rock Salt;
  font-size: 30px;
  font-weight: normal;
  position: relative;
  margin-top: 0px;
}

.Teaching-Text-Container {
  display: inline-block;
  font-size: 17px;
  width: 425px;
  height: 350px;
  overflow: scroll;
  /* margin-top: 15px; */
  padding: 0 10px;
  position: relative;
}

.Teaching-Section {
}

.dflogo{
 width:75%;
}

.Teaching-Section-Title {
  display: block;
  font-family: Rock Salt;
  font-size: 17px;
  border: 1px solid rgba(192, 170, 210, 0);
  border-radius: 10px;
  margin: 0px;
  transition: .5s;
}

.Teaching-Section-Title:hover {
  cursor: pointer;
  transition: .5s;
  /* text-decoration: underline; */
  border: 1px solid #C0AAD2;
  border-radius: 10px;
}

.Teaching-Section-Title-Active {
  border: 1px solid #C0AAD2;
  border-radius: 10px;
  transition: .5s;
}

.Teaching-Info {
  font-size: 17px;
  margin: 0 2px 8px 2px;
}

.Teaching-Quote {
  margin-top: 0px;
  /* font-style: italic; */
  /* font-weight: 600; */
  font-size: 10px;
  font-family: Rock Salt;
}

.Teaching-Image-Container{
  display: inline-block;
  /* position: absolute; */
  top: 0px;
}

.Teaching-Photo-Thumb {
  height: 100px;
  width: 100px;
  object-fit: cover;
  border-radius: 50%;
  margin-top: 8px;
  display: block;
  border: 3px solid #C0AAD2;
}

.Teaching-Image-Container-Mobile{
  display: none;
}

.Teaching-Hide{
  display: none;
}

@media only screen and (max-width: 700px) {

  .Teaching-Image-Container{
    display: none;
  }

  .Teaching-Image-Container-Mobile{
    display: flex;
    position: relative;
    margin-right: 0;
  }

  .Teaching-Photo-Thumb-Mobile {
    height: 90px;
    width: 90px;
    object-fit: cover;
    border-radius: 50%;
    margin: 0 0 0 10px;
    display: block;
    border: 3px solid #C0AAD2;
    display: inline-block;
    flex-direction: row;
    position: relative;
  }
  h1 {
    margin: 0;
  }

  .Teaching-Text-Container {
    width: 300px;
    height: 100%;
    overflow: visible;
    /* margin-top: 100px; */
  }

  .Teaching-Container {
    height: 38vh;
  }

}
