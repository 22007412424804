.About-Text {
  display: inline-block;
  font-size: 17px;
  width: 500px;
  height: 300px;
  overflow: scroll;
  padding-left: 50px;
  margin-top: 40px;
  text-align: left;
  position: relative;
}

.About-Photo {
  display: inline-block;
  height: 290px;
  width: 290px;
  object-fit: cover;
  border-radius: 50%;
  border: 4px solid #C0AAD2;
  box-sizing: border-box;
}

.About-Header {
  text-align: center;
  font-family: Rock Salt;
  font-size: 30px;
  margin-top: 0px;
  margin-bottom: 0px;
  font-weight: normal
}

@media only screen and (max-width: 900px) {
  .About-Photo {
    position: static;
    /* flex-direction: row; */
    /* margin-top: 70px; */
    height: 240px;
    width: 240px;
    z-index: 0;
  }
  .About-Text {
    display: inline-block;
    font-size: 17px;
    width: 400px;
    height: 300px;
    overflow: scroll;
    padding-left: 50px;
    margin-top: 40px;
    text-align: left;
    position: static;
  }
}

@media only screen and (max-width: 700px) {
  .About-Photo {
    position: relative;
    /* flex-direction: row; */
    /* margin-top: 70px; */
    height: 160px;
    width: 160px;
    z-index: 0;
  }
  .About-Text {
    display: inline-block;
    font-size: 15px;
    width: 75%;
    overflow: visible;
    padding-left: 15px;
    padding-right: 8px;
    margin-top: 0px;
    text-align: left;
    position: relative;
  }

}
