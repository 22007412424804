.Gallery-Header{
  text-align: center;
  font-family: Rock Salt;
  font-size: 30px;
  margin-top: 0px;
  margin-bottom: 10px;
  font-weight: normal;
}

.Photo-Thumb, .overlay {
  height: 140px;
  width: 140px;
  object-fit: cover;
  border-radius: 50%;
  margin: 0 10px;
}

.Photo-Thumb:hover {
  cursor: pointer;

}

.Img-Container {
  margin: 0 10px;
  display: inline-block;
  position: relative;
  height: 140px;
  width: 140px;
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  /* left: 0; */
  /* right: 0; */
  /* height: 100%;
  width: 100%; */
  opacity: 0;
  transition: .5s ease;
  background: rgba(65, 63, 113, 0.75);
  border: 1px solid #495261;
  box-sizing: border-box;
}

.Img-Container:hover .overlay {
  opacity: 1;
  cursor: pointer;
  transition: .5s ease;
}

.text {
  color: white;
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

@media only screen and (max-width: 700px) {
  .Img-Container {
    height: 120px;
    width: 120px;
  }

  .Photo-Thumb, .overlay {
    height: 120px;
    width: 120px;
  }
  .overlay {
    display: none;
  }

  .Img-Container:hover .overlay {
    opacity: 1;
    display: block;
  }

}
