.Contact-Header{
  text-align: center;
  font-family: Rock Salt;
  font-size: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: normal;
}

.Contact-Location {
  font-size: .7em;
}

@media only screen and (max-width: 700px) {
  .Contact-Location {
    font-size: 10px;
  }
}
