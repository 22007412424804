body {
  background-color: #E7E2EB;
  -webkit-overflow-scrolling: touch;
}

.App {
  text-align: center;
  z-index: -4;
  box-sizing: border-box;
}

.App-main {
  z-index: -3;
  min-height: 100vh;
  display:  flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #413F71;
  font-family: Thasadith;
  background: radial-gradient(24.69% 33.97% at 49.31% 46.88%, rgba(255, 255, 255, 0.45) 0%, #E7E2EB 100%);
}
.app-mid{
  height: 380px;
  width: 100%;
  overflow: scroll;
  position: fixed;
  top: 200px;
}

.App-Header {
  position: fixed;
  top: 0;
  /* background-color: #E7E2EB; */
  font-family: 'Rock Salt', cursive;
  font-size: 14px;
  width: 100%;
}

.App-Footer {
  background-color: #CFC0DB;
  border: 1px solid #B4A9BC;
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 13%;
  font-family: 'Rock Salt', cursive;
  font-size: 12px;
}

.App-Footer p {
  display: inline-block;
  padding: 10px;
}

.Nav-Links {
  list-style-type: none;
  position: relative;
  display: inline-block;
}

.Body-Link:hover {
  text-decoration: underline;
}

.Menu-Div {
  display: inline-block;
}

ul {
  margin-top: 0;
}

li {
    float: left;
}

.Nav-Bar {
  height: 100%;
}

.Nav-Link {
  margin: 0 10px;
  padding: 5% 0;
  float: left;
  width: 85px;
  height: 80px;
  background: rgba(187, 162, 206, 0.54);
  border: 1px solid #B4A9BC;
  border-radius: 50%;
  box-sizing: border-box;
  text-decoration: none;
  color: #E7E2EB;
  transition: .5s;
}

.Nav-Link:hover {
  border: 1px solid #413F71;
  color: #FFFFFF;
  transition: .5s;
}


.Nav-Link-Text {
  text-align: center;
  text-decoration: none;

}

.Link-Active {
  background: rgba(187, 162, 206, 0.87);
  color: #efeaf4;
}

.Logo-Text {
  text-decoration: none;
  font-size: 18px;
}

a {
  text-decoration: none;
  color: #413F71;
}

.logo.container {
	 text-align: center;
}
 .logo.container .logo {
	 position: relative;
	 display: inline-block;
}
 .logo.container .logo .wing {
	 background-color: rgba(172, 195, 230, 0.25);
	 width: 301.83px;
	 height: 53.71px;
	 border-radius: 100%;
	 position: absolute;
	 left: -7%;
	 transform: translateY(4%) rotate(1deg);
}
 .logo.container .logo .wing#wing-1 {
	 transform: translateY(-30%) rotate(-3.3deg);
}
 .logo.container .logo .title {
	 position: relative;
	 text-transform: uppercase;
	 font-size: 1.1rem;
	 line-height: 1rem;
	 font-weight: normal;
	 color: #413F71;
   margin-bottom: 0;
}
 .logo.container .logo .dragonfy-icon {
   height: 80px;
	 position: absolute;
	 top: 19%;
   right: -57px;
   transform: rotate(80deg)
}

.Mobile-Menu {
  display: none;
}

.Home-h1{
  font-family: Rock Salt;
  font-size: 30px;
  font-weight: normal;
}

.Home-h2{
  font-family: Rock Salt;
  font-size: 14px;
  font-weight: normal;
}

.Home-Body{
  font-size: 17px;
}
.Home-Page {
  width: 80%;
  text-align: center;
  padding-left: 10%
}
@media only screen and (max-width: 900px) {

  .Home-h1{
    font-family: Rock Salt;
    font-size: 1em;
    font-weight: normal;
  }

  .Home-h2{
    font-family: Rock Salt;
    font-size: .6em;
    font-weight: normal;
  }

  .Home-Body{
    font-size: .7em;
  }
  .app-mid{
    height: 350px;
    width: 100%;
    overflow: scroll;
    position: fixed;
    top: 180px;
  }
}

@media only screen and (max-width: 700px) {

  .App-main {
    min-height: 80vh;
  }

  .Mobile-Menu-Button {
    display: inline-block;
    font-size: 16px;
    font-family: Rock Salt;
    margin: 5px 10px;
    width: 85px;
    height: 40px;
    background: rgba(187, 162, 206, 0.87);
    border: 1px solid #B4A9BC;
    border-radius: 50%;
    box-sizing: border-box;
    text-decoration: none;
    cursor: pointer;
    color: #efeaf4;

  }
  .Nav-Links.Hide {
    display: none;
  }
  .Menu-Open {
    /* background-color: #413F71; */
    /* background: radial-gradient(24.69% 33.97% at 49.31% 46.88%, #2f2e50 10%, #413F71 90%); */
    background-image: linear-gradient(rgb(66, 64, 113), #2f2e50);
    border-width: 0 0 3px 0;
    border-style: solid;
    border-color: #413F71;
    position: fixed;
    z-index: 1;
  }
  .Nav-Bar{
    align: center;
  }
  .Close-Menu{
    display: inline-block;
    top: 5px;
    left: 2px;
    position: absolute;
    background-color: #413F71;
    color: #efeaf4;
    font-size: 26px;
    font-weight: bolder;
    border: 1px solid #B4A9BC;
    border-radius: 50%;
    box-sizing: border-box;
    cursor: pointer;
  }
  .Nav-Links {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .Menu-Div{
    margin: 5px;
  }
  .logo.container .logo .wing {
 	 width: 275px;
 	 height: 40px;
  }
  .logo.container .logo .title {
    font-size: .99rem;
    margin-top: 6px;
    margin-bottom: 0;
  }
  .logo.container .logo .dragonfy-icon {
    height: 50px;
    top: 22%;
    right: -37px;
  }
  .App-Footer{
  position: fixed;
  height: 10%;
  }
  .App-Footer p {
    display: block;
    line-height: 0.4;
    box-sizing: border-box;
    padding: 0;
    margin-top: 8px;
    font-size: .8em;
  }
  #Footer-Top-Line {
    padding-top: 8px;
  }
  .app-mid{
    height: 55vh;
    width: 100%;
    overflow: scroll;
    position: fixed;
    top: 150px;
  }

  /* .Home-h1{
    font-family: Rock Salt;
    font-size: 1em;
    font-weight: normal;
  }

  .Home-h2{
    font-family: Rock Salt;
    font-size: .6em;
    font-weight: normal;
  }

  .Home-Body{
    font-size: .8em;
  } */

}
  @media only screen and (max-width: 320px) {
    .app-mid{
    height: 320px;
  }
}
